



























































import { parseDate } from '@/utils';
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { dispatchGetLogs, dispatchDeleteLogs } from "@/store/main/actions";
import { readMqttLogs } from "@/store/main/getters";

@Component
export default class Logs extends Vue {
  public dialog = false;
  public parseDate = parseDate;
  public headers = [
    {
      text: 'Project',
      sortable: true,
      value: 'project_name',
      align: 'left',
    },
    {
      text: 'By User',
      sortable: true,
      value: 'user_requested',
      align: 'left',
    },
    {
      text: 'Group Name',
      sortable: true,
      value: 'group_name',
      align: 'left',
    },
    {
      text: 'Topic',
      sortable: true,
      value: 'topic',
      align: 'left',
    },
    {
      text: 'Created At',
      sortable: true,
      value: 'created_at',
      align: 'left',
    },
    {
      text: 'Sent At',
      sortable: true,
      value: 'sent_at',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'id',
      align: 'center',
    },
  ];

  public pagination = {
    ascending: true,
    sortBy: 'name',
    rowsPerPage: 25,
    page: 1,
  };

  get logs() {
    return readMqttLogs(this.$store);
  }
  public async mounted() {
    await dispatchGetLogs(this.$store);
  }

  public async confirm() {
    this.dialog = false;
    await dispatchDeleteLogs(this.$store);
    await dispatchGetLogs(this.$store);
    this.$router.push('/main/mqtt_logs/all');
  }
}
